import { useOverlays } from '~/composables/useOverlays'
import useUiState from '~/composables/ui/useUiState'

export const useNewsletters = async () => {
  const instance = useNuxtApp()
  const scope = effectScope()
  tryOnScopeDispose(() => scope.stop())
  const { data: newsletters, fetch, fetching } = await useOverlays('newsletter')

  return instance.runWithContext(() => {
    const { isRxConfiguratorOverlayOpen } = useUiState()
    const isVisible = ref(false)
    /**
     * Only one overlay can be visible per page, select the first one in case
     * multiple satisfy filtering criteria, make sure to exclude already dismissed
     */
    const newsletter = computed(() => {
      if (newsletters.value.length) {
        return newsletters.value.find((nl) => {
          const cookie = instance.runWithContext(
            () =>
              useJsonCookie<boolean>(`newsletter-dismissed-${nl.uuid}`).value,
          )
          return !cookie
        })
      }
    })

    const localKey = computed(() =>
      newsletter.value ? `newsletter-dismissed-${newsletter.value.uuid}` : '',
    )

    const showAfter = computed(() =>
      newsletter.value && newsletter.value.content.show_after
        ? parseInt(String(newsletter.value.content.show_after), 10) * 1000
        : 0,
    )

    const timer = ref<ReturnType<typeof setTimeout>>()

    const setVisibility = () => {
      if (import.meta.client && newsletter.value) {
        if (timer.value) {
          clearTimeout(timer.value)
        }
        timer.value = setTimeout(() => {
          isVisible.value = Boolean(
            !fetching.value &&
              newsletters.value.length &&
              !isRxConfiguratorOverlayOpen.value,
          )
        }, showAfter.value)
      }
    }

    setVisibility()

    scope.run(() => {
      if (import.meta.server) {
        return
      }
      watch(newsletter, () => {
        setVisibility()
      })
    })
    /**
     * Once dismissed this newsletter should not show again for one month
     */
    const SECONDS_IN_MONTH = 60 * 60 * 24 * 30
    const close = () => {
      isVisible.value = false
      const cookie = useJsonCookie<boolean>(localKey.value, {
        maxAge: SECONDS_IN_MONTH,
      })

      cookie.value = true
    }

    return {
      newsletter,
      fetch,
      isVisible,
      close,
    }
  })
}
