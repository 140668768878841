import type { RpcMethodParameters } from '@scayle/storefront-core'
import { CmsBaseFolder } from '~/constants/cms'

type Options = Partial<{
  params: MaybeRefOrGetter<Partial<RpcMethodParameters<'getCmsStories'>>>
  options?: Parameters<typeof useRpc>[3]
  key: string
}>

export const useCmsStories = ({
  params,
  options,
  key = 'useCmsStories',
}: Options = {}) => {
  const currentShop = useCurrentShop()

  const localizedBaseFolder = getLocalizedBaseFolder(
    currentShop.value,
    toValue(params)?.baseFolder || CmsBaseFolder.Content,
  )

  const { isInEditorMode } = useStoryblokHelpers()
  const domainKey = computed(
    () => `${currentShop.value?.domain}-${key}-${localizedBaseFolder}`,
  )

  return useRpc(
    'getCmsStories',
    `${domainKey.value}`,
    {
      folder: '',
      ignoreNotFound: true,
      ...params,
      baseFolder: localizedBaseFolder,
      isCmsPreview: isInEditorMode.value,
    },
    options,
  )
}
