<template>
  <div
    class="anchor-scrolling-none flex min-h-screen flex-col text-primary antialiased"
  >
    <div class="overflow-x-hidden">
      <ClientOnly>
        <aside>
          <template v-for="(banner, i) in banners" :key="`banner-${i}`">
            <LazyBanner
              v-if="
                shouldBeVisible(
                  {
                    ...banner.content,
                    published_at: banner.published_at!,
                  },
                  banner.uuid,
                )
              "
              :blok="{
                ...banner.content,
                published_at: banner.published_at!,
              }"
              :uuid="banner.uuid"
            />
          </template>
        </aside>
      </ClientOnly>
    </div>
    <AppHeader
      v-bind="{
        fetching: fetchingNavigationTree,
        navigationTree: navigationTree || [],
      }"
    />
    <LazyToastContainer v-if="$alert.alerts.value.length" />
    <LazyMobileNavigation
      v-if="isLessThan('header')"
      :fetching="fetchingNavigationTree"
      :navigation-tree="navigationTree || []"
    />
    <main
      :class="{ 'min-h-[80vh]': !isAccountArea, 'min-h-[40vh]': isAccountArea }"
    >
      <NuxtPage data-testid="home-page-content" />
    </main>
    <NuxtLazyHydrate when-visible>
      <AppFooter v-once />
    </NuxtLazyHydrate>

    <ClientOnly>
      <template v-if="newsletter">
        <Overlay
          portal-target="#newsletter-overlay"
          :open="isNewsletterVisible"
          :no-padding="true"
          :show-title="false"
          :mobile-windowed="true"
          @update:open="closeNewsletter"
        >
          <Newsletter
            :blok="newsletter.content"
            :uuid="newsletter.uuid"
            layout="vertical"
            @update:open="closeNewsletter"
          />
        </Overlay>
      </template>
    </ClientOnly>

    <ClientOnly>
      <RopoOverlay />
    </ClientOnly>

    <ClientOnly>
      <LanguageSwitcherOverlay />
    </ClientOnly>

    <div id="ropo-overlay"></div>
    <div id="product-detail-size-portal" data-testid="sizes-overlay"></div>
    <div id="rx-find-portal"></div>
    <div id="rx-sight-prescription-portal"></div>
    <div id="rx-pd-tool-portal"></div>
    <div id="rx-lens-quality-portal"></div>
    <div id="rx-extras-portal"></div>
    <div id="newsletter-overlay"></div>
    <div id="language-overlay"></div>
    <div id="info-overlay" data-testid="info-overlay"></div>
  </div>
</template>

<script setup lang="ts">
import useUiState from '~/composables/ui/useUiState'

const { isLessThan } = useViewport()
const {
  isDialogOpen,
  isSearchFlyoutOpen,
  isOverlayOpen,
  isRxConfiguratorOverlayOpen,
  openLanguageSwitcherOverlay,
} = useUiState()

const route = useRoute()
const switchLocalePath = useSwitchLocalePath()
const nuxtApp = useNuxtApp()
const currentShop = useCurrentShop()
const localePath = useFimLocalePath()
const { configuredLocale, isMultilanguageShop, hasNoLanguagePreference } =
  useLocale()
const getRouteBaseName = useRouteBaseName()
// Need to make sure to have all RX product options so price can be
// calculated in useProductPrice
useRxProducts()

const scope = effectScope()
onScopeDispose(() => scope.stop())

/**
 * Meta
 */
const { isSideNavigationOpen } = useSideNavigation()
const { isOpen: isModalOpen } = useModal()
const isAccountArea = computed(() => route?.path?.includes('account'))

const i18nHead = useLocaleHead({
  addDirAttribute: true,
  addSeoAttributes: true,
})

useHead({
  htmlAttrs: {
    ...i18nHead.value.htmlAttrs,
  },

  bodyAttrs: () => ({
    class: [
      'relative',
      isSideNavigationOpen.value || isModalOpen.value
        ? 'overflow-hidden h-full'
        : '',
      isSideNavigationOpen.value ||
      isModalOpen.value ||
      isDialogOpen.value ||
      isSearchFlyoutOpen.value ||
      isOverlayOpen.value ||
      isRxConfiguratorOverlayOpen.value
        ? 'overscroll-none'
        : '',
      isOverlayOpen.value || isRxConfiguratorOverlayOpen.value
        ? 'overflow-hidden'
        : '',
    ],
  }),
})

scope.run(() => {
  if (import.meta.server) {
    return
  }
  watch(
    configuredLocale,
    async (newLang) => {
      if (
        !isMultilanguageShop.value ||
        !newLang ||
        currentShop.value.locale === newLang
      ) {
        return
      }
      const trueRouteName = getRouteBaseName(route)
      const routeMatch = Object.entries(routeList).some(
        ([_, route]) => route.name === trueRouteName,
      )
      if (!routeMatch) {
        return
      }

      const url = switchLocalePath(newLang.toLowerCase())
      await navigateTo(url, { redirectCode: 302, external: true })
    },
    { immediate: true },
  )
})

if (isMultilanguageShop.value && hasNoLanguagePreference.value) {
  openLanguageSwitcherOverlay()
}

const { data: navigationTree, pending: fetchingNavigationTree } =
  useFimNavigationTree()

const { banners, shouldBeVisible } = await useBanners()

const {
  newsletter,
  fetch: fetchNewsletters,
  isVisible: isNewsletterVisible,
  close: closeNewsletter,
} = await useNewsletters()

await fetchNewsletters()
await Promise.all([useWishlist, useBasket])

/**
 * @todo tracking
 */

// const { trackShopInit, listenToUserItemsChanges, listenToCustomerDataChanges } =
//   await useTrackingEvents()
// trackShopInit()
// await listenToUserItemsChanges()
// await listenToCustomerDataChanges()

/**
 * Error handling
 */
onErrorCaptured((err, target, info) => {
  throw createError({
    statusCode: 500,
    statusMessage: err.message,
    ...err,
    fatal: true,
  })
})

onMounted(() => {
  console.log('DEBUG: currentShop', currentShop.value)
})

defineOptions({ name: 'AppDefault' })
</script>
