import { useSessionStorage } from '@vueuse/core'
import { useOverlays } from '~/composables/useOverlays'
import type { SbGlobalBanner } from '~/storyblok/types/storyblok'

export const useBanners = async () => {
  const nuxtApp = useNuxtApp()
  const { data, fetch } = await useOverlays('banner')

  return nuxtApp.runWithContext(() => {
    /**
     * Keep track of closed banners in session storage
     */
    const localKey = 'bannersLastCloseAt'
    const closed: any = import.meta.client
      ? useSessionStorage(localKey, {} as Record<string, string>)
      : undefined

    const shouldBeVisible = (
      banner: SbGlobalBanner & { published_at?: string },
      uuid: string,
    ): boolean => {
      if (import.meta.client && closed?.value) {
        const lastClosedAt = closed.value[uuid]
        if (banner.published_at && lastClosedAt) {
          return Date.parse(banner.published_at) > Number.parseInt(lastClosedAt)
        }
      }
      return true
    }

    const close = (uuid: string) => {
      if (import.meta.client && closed?.value) {
        closed.value[uuid] = Date.now().toString()
      }
    }

    return {
      banners: data,
      fetch,
      shouldBeVisible,
      close,
    }
  })
}
